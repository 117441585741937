import React, { useState, useCallback } from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Parallax } from "react-scroll-parallax"
import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
  StarIcon,
} from "@heroicons/react/solid"
import CaseCarousel from "components/CaseCarousel"
import Section from "components/Section"
import MemberCarousel from "components/MemberCarousel"
import CaseCard from "components/CaseCard"
import MemeberCard from "components/MemberCard"
import MemberDrawer from "components/MemberDrawer"
import MemberDetails from "components/MemberDetails"
import Hero from "components/Hero"
import ServicesCollaspe from "components/ServicesCollaspe"
import SectionContactCta from "components/SectionContactCta"

import QuoteStartIcon from "images/icons/icon-quote-start.png"
import LogoSamsungfire from "images/clients/logo-samsungfire.jpg"
import LogoSamsunglife from "images/clients/logo-samsunglife.png"
import LogoSeoulMedicalCenter from "images/clients/logo-seoul-medical-center.png"
import LogoKeli from "images/clients/logo-keli.png"
import LogoYungjin from "images/clients/logo-yungjin.jpg"
import { isNull, findIndex } from "lodash"
import Seo from "components/Seo"

export default function IndexPage({ data: { allWpConsultant, allWpCase } }) {
  const consultants = allWpConsultant?.nodes
  const cases = allWpCase?.nodes

  const [visibleConsultantDetails, setVisibleConsultantDetails] = useState(null)
  const selectedConsultantIndex = findIndex(
    consultants,
    item => item.id === visibleConsultantDetails
  )
  const selectedConsultant =
    selectedConsultantIndex !== -1 ? consultants[selectedConsultantIndex] : null

  const renderSelectedConsultant = useCallback(() => {
    return <MemberDetails source={selectedConsultant} />
  }, [selectedConsultant])

  return (
    <div>
      <Seo
        strict
        title={"HPC컨설팅"}
        description="일터혁신, 조직진단, 직무분석, 성과관리시스템, 임금체계개선, 교육훈련체계, 경력개발체계 구축, 조직문화개선, 유연근무제, 제조혁신, 스마트공장"
      />
      <MemberDrawer
        open={!isNull(visibleConsultantDetails)}
        onOepn={() => console.log("open")}
        onClose={() => setVisibleConsultantDetails(null)}
      >
        {selectedConsultant && renderSelectedConsultant()}
      </MemberDrawer>
      <Hero />
      <ServicesCollaspe />

      <Section>
        <div className="w-full lg:container mx-auto px-4 lg:px-8">
          <div className="mb-3 lg:mb-6">
            <div className="flex flex-col lg:flex-row flex-wrap lg:items-end justify-between">
              <div className="mb-4 lg:mb-0">
                <Section.Headline eyebrow={"Case Studies"}>
                  HPC컨설팅과 함께한 회사들의
                  <br className="hidden lg:block" />
                  이야기를 들어보세요.
                </Section.Headline>
              </div>
              <div className="flex flex-row flex-wrap">
                <div className="px-1">
                  <button className="hpc-swiper-button case-carousel-swiper-button-prev">
                    <ArrowNarrowLeftIcon className="w-5 h-5" />
                  </button>
                </div>
                <div className="px-1">
                  <button className="hpc-swiper-button case-carousel-swiper-button-next">
                    <ArrowNarrowRightIcon className="w-5 h-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="swiper-overflow-container">
          <div className="overflow-container w-full lg:container mx-auto px-4 lg:px-8">
            <CaseCarousel
              source={cases}
              renderItem={item => {
                const { caseCategories, uri } = item
                return (
                  <div className="pt-4 flex  w-full h-full" key={item.id}>
                    <CaseCard
                      url={uri}
                      consultant={item.case.consultant}
                      eyebrow={
                        <ul>
                          {caseCategories.nodes.map(
                            (item, index) =>
                              !index && (
                                <li
                                  key={item.id}
                                  className="text-sm lg:text-base text-primary font-medium"
                                >
                                  {item.name}
                                </li>
                              )
                          )}
                        </ul>
                      }
                      title={item.title}
                      featuredImage={
                        <div className="relative overflow-hidden">
                          <div
                            className="bg-gray-200"
                            style={{ paddingBottom: "56.25%" }}
                          ></div>
                          <GatsbyImage
                            style={{
                              position: "absolute",
                              height: "100%",
                              width: "100%",
                              inset: 0,
                              objectFit: "cover",
                              zIndex: 10,
                            }}
                            image={
                              item.featuredImage?.node?.localFile
                                ?.childImageSharp?.gatsbyImageData
                            }
                            alt={item.featuredImage?.node?.alt || ``}
                          />

                          <div className="z-20 absolute inset-0 w-full h-full flex items-end justify-start">
                            {item?.case.consulting && (
                              <div className="flex flex-row flex-wrap my-3 mx-3">
                                {item.case?.consulting?.type && (
                                  <div className="px-1">
                                    <span className="bg-primary text-white shadow-md border-1 border-gray-100 text-sm px-2 rounded-full font-medium">
                                      {item.case.consulting.type}
                                    </span>
                                  </div>
                                )}
                                {item.case?.consulting?.age && (
                                  <div className="px-1">
                                    <span className="bg-primary text-white shadow-md border-1 border-gray-100 text-sm px-2 rounded-full font-medium">
                                      {item.case.consulting.age}
                                    </span>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      }
                    />
                  </div>
                )
              }}
            />
          </div>
        </div>
        <div className="text-center mt-8">
          <Link
            to="/cases"
            className="transition duration-200 border border-gray-300 text-gray-500 hover:border-primary hover:text-white hover:bg-primary rounded-full px-6 py-2 inline-block"
          >
            성공사례 전체보기
          </Link>
        </div>
      </Section>

      <div className=" relative overflow-hidden">
        <Parallax x={[100, 20]} tagOuter="figure">
          <h4 className="absolute z-10 tracking-wider text-5xl sm:text-8xl text-gray-100 font-bold mt-14">
            HUMAN & PRODUCTIVITY <br />
            INNOVATORS
          </h4>
        </Parallax>
        <Section>
          <div className="mb-8 lg:mb-10">
            <div className="w-full lg:container mx-auto px-4 lg:px-8">
              <div className="flex flex-col lg:flex-row lg:items-end justify-between relative z-20">
                <div className="mb-4 lg:mb-0">
                  <Section.Headline eyebrow={"Who We Are"}>
                    뛰어난 현장 전문가들이 <br />
                    기업의 혁신을 지원합니다.
                  </Section.Headline>
                </div>
                <div className="flex flex-row flex-wrap">
                  <div className="px-1">
                    <button className="hpc-swiper-button member-carousel-swiper-button-prev">
                      <ArrowNarrowLeftIcon className="w-5 h-5" />
                    </button>
                  </div>
                  <div className="px-1">
                    <button className="hpc-swiper-button member-carousel-swiper-button-next">
                      <ArrowNarrowRightIcon className="w-5 h-5" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:container mx-auto px-4 lg:px-8">
            <MemberCarousel
              source={consultants}
              renderItem={item => {
                const { id, title, consultant } = item
                const featuredImage = {
                  data: item.featuredImage?.node?.localFile?.childImageSharp
                    ?.gatsbyImageData,
                  alt: item.featuredImage?.node?.alt || ``,
                }

                return (
                  <MemeberCard
                    onClick={() => setVisibleConsultantDetails(id)}
                    avatar={
                      <GatsbyImage
                        style={{
                          position: "absolute",
                          height: "100%",
                          width: "100%",
                          inset: 0,
                          objectFit: "cover",
                        }}
                        image={featuredImage.data}
                        alt={featuredImage.alt}
                      />
                    }
                    speciality={consultant?.speciality}
                    role={consultant?.role}
                    name={title}
                    id={id}
                  />
                )
              }}
            />
          </div>
          <div className="text-center mt-8">
            <Link
              to="/consultants"
              className="transition duration-200 border border-gray-300 text-gray-500 hover:border-primary hover:text-white hover:bg-primary rounded-full px-6 py-2 inline-block"
            >
              컨설턴트 전체보기
            </Link>
          </div>
        </Section>
      </div>

      <div className="bg-white">
        <Section>
          <div className="w-full lg:container mx-auto px-4 lg:px-8">
            <div className="text-center mb-8">
              <Section.Headline eyebrow={"Testimonials"}>
                이미 많은 기업이 혁신을 위해 <br className="hidden lg:block" />
                HPC컨설팅을 선택하고 있습니다.
              </Section.Headline>
            </div>
          </div>
          <div className="w-full lg:container mx-auto px-4 lg:px-8 text-center flex justify-center">
            <div className="mb-4 max-w-screen-xl flex flex-row flex-wrap -mx-4 lg:-mx-8">
              {[
                {
                  key: "testimonial-1",
                  testimonial: `연공서열 중심의 6직급체계를 역할 중심의 4직급체계로 단순화하여 
 성과/능력주의 조직문화를 구축하고 우수 인력의 유출을 방지할 수 있었습니다.`,
                  client: "(주)경우시스테크",
                  name: "이학렬 차장",
                },
                {
                  key: "testimonial-2",
                  testimonial: `수작업 조립라인을 자동화하고, 생산직 다기능 평가/교육체계를 설계하여 
                  고객의 수요변화에 유연하게 대응할 수 있는 효율적인 생산시스템을 구축할 수 있었습니다.
                  `,
                  client: "(주)고모텍",
                  name: "홍연정 부장",
                },
              ].map(item => {
                return (
                  <div
                    className="w-full lg:w-1/2 px-4 lg:px-8 mb-12 lg:mb-0 last:mb-0"
                    key={item.key}
                  >
                    <h3 className="font-medium text-gray-900 max-w-lg mx-auto text-lg lg:text-xl break-words leading-relaxed relative">
                      <img
                        src={QuoteStartIcon}
                        alt="Quote"
                        className="h-8 mx-auto mb-4"
                      />
                      {item.testimonial}
                    </h3>
                    <div className="my-3">
                      <div className="inline-flex flex-row flex-wrap ">
                        <StarIcon className="text-yellow-400 w-6 h-6" />
                        <StarIcon className="text-yellow-400 w-6 h-6" />
                        <StarIcon className="text-yellow-400 w-6 h-6" />
                        <StarIcon className="text-yellow-400 w-6 h-6" />
                        <StarIcon className="text-yellow-400 w-6 h-6" />
                      </div>
                    </div>
                    <div className="flex flex-row flex-wrap items-center mt-5 justify-center">
                      {/* <div className="mr-3">
                        <img
                          src="https://pony.studio/assets/reviews/stephen.jpg"
                          alt=""
                          className="w-12 h-12 rounded-full"
                        />
                      </div> */}
                      <div className="flex flex-col text-center">
                        <h4 className="text-gray-900 font-medium">
                          {item.client}
                        </h4>
                        <h5 className="text-gray-500">{item.name}</h5>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="lg:container mx-auto px-4 lg:px-8 flex justify-center mt-8 lg:mt-16">
            <div className="flex flex-row flex-wrap -mx-4 lg:-mx-8 justify-center">
              {[
                { label: "삼성화재", icon: LogoSamsungfire },
                { label: "삼성생명", icon: LogoSamsunglife },
                { label: "영진약품", icon: LogoYungjin },
                { label: "서울의료원", icon: LogoSeoulMedicalCenter },
                { label: "한국고용노동교육원", icon: LogoKeli },
              ].map((item, index) => {
                return (
                  <div className="px-4 lg:px-8 py-5" key={`client-${index}`}>
                    <img
                      src={item.icon}
                      alt=""
                      className="h-8 lg:h-12 grayscale hover:grayscale-0"
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </Section>
      </div>

      <SectionContactCta />
    </div>
  )
}

export const pageQuery = graphql`
  query IndexPageQuery {
    # allWpPost(limit: 3) {
    #   nodes {
    #     id
    #     title
    #     slug
    #     uri
    #     date(formatString: "yyyy년 MM월 DD일", locale: "ko")
    #     categories {
    #       nodes {
    #         id
    #         name
    #         slug
    #       }
    #     }
    #     featuredImage {
    #       node {
    #         localFile {
    #           childImageSharp {
    #             gatsbyImageData
    #           }
    #         }
    #       }
    #     }
    #   }
    # }
    allWpCase(sort: { fields: [date], order: DESC }, limit: 9) {
      nodes {
        id
        date
        slug
        title
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        caseCategories {
          nodes {
            id
            name
            slug
            uri
          }
        }
        case {
          consultant {
            ... on WpConsultant {
              id
              slug
              uri
              title
              consultant {
                role
                speciality
              }
              featuredImage {
                node {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
          consulting {
            age
            type
          }
        }
        uri
      }
    }
    allWpConsultant(
      filter: { featuredImage: { node: { id: { ne: null } } } }
      sort: { fields: [date], order: ASC }
      limit: 15
    ) {
      nodes {
        id
        title
        consultant {
          role
          speciality
          awards
          education
          experience
          lecture
          role
          speciality
          fieldGroupName
          qutoe {
            fieldGroupName
            description
            title
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FIXED)
              }
            }
          }
        }
      }
    }
  }
`
